import styled from "@emotion/styled";
import FormControl from "@mui/material/FormControl";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select, { type SelectChangeEvent } from "@mui/material/Select";
import Box from "@mui/system/Box";
import { COLORS, theme } from "@stacklet/ui";

import { useConfig } from "app/contexts";
import { useStickyState } from "app/hooks";
import { formatProvider } from "app/utils/formatProvider";

import { ProviderIcon } from "./icons/ProviderIcon";

import type { Provider } from "app/contexts/ProviderContext";

const pxToRem = theme.typography.pxToRem;
const CustomSelect = styled(Select)(() => ({
  "&.MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: COLORS.navy.L50,
    },
    "&:hover fieldset": {
      borderColor: COLORS.orange.base,
    },
    "& .MuiSvgIcon-root": { color: "white" },
  },
}));

interface Props {
  onChange: (provider: Provider) => void;
}

export function ProviderSelector({ onChange }: Props) {
  const [selectedProvider, setSelectedProvider] = useStickyState(
    "aws",
    "provider",
  );

  const config = useConfig();

  const providers = config?.cloud_providers ?? [];

  function handleChange(e: SelectChangeEvent<unknown>) {
    setSelectedProvider(e.target.value as Provider);
    onChange(e.target.value as Provider);
  }

  const disabled = providers.length === 1;

  return (
    <FormControl sx={{ m: 2 }}>
      <CustomSelect
        disabled={disabled}
        inputProps={{
          sx: {
            padding: `${pxToRem(5)} ${pxToRem(8)}`,
            alignItems: "center",
          },
          MenuProps: {
            MenuListProps: {
              sx: {
                backgroundColor: "secondary.dark",
              },
            },
          },
        }}
        label="Age"
        labelId="selected-provider"
        notched={false}
        onChange={handleChange}
        size="small"
        sx={{
          color: "white",
          borderColor: COLORS.navy.L50,
          border: "3px",
        }}
        value={selectedProvider}
      >
        {providers.map((provider) => (
          <MenuItem
            key={provider}
            sx={{
              color: "white",
            }}
            value={provider}
          >
            <Box
              sx={{
                flexDirection: "row",
                display: "flex",
              }}
            >
              <ListItemIcon>
                <ProviderIcon provider={formatProvider(provider)} invert />
              </ListItemIcon>
              <ListItemText>{formatProvider(provider)}</ListItemText>
            </Box>
          </MenuItem>
        ))}
      </CustomSelect>
    </FormControl>
  );
}
