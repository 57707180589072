import Box from "@mui/material/Box";
import { Tooltip } from "@stacklet/ui";
import { nanoid } from "nanoid";

import AwsLogoWhite from "assets/icons/providers/aws-white.svg?react";
import AwsLogo from "assets/icons/providers/aws.svg?react";
import AzureLogo from "assets/icons/providers/azure.svg?react";
import GcpLogo from "assets/icons/providers/gcp.svg?react";
import KubernetesLogo from "assets/icons/providers/k8s.svg?react";
import TencentCloudLogo from "assets/icons/providers/tencentcloud.svg?react";

import type { CloudProvider } from "app/mutations/__generated__/AddAccountGroupMutation.graphql";

interface Props {
  provider: CloudProvider;
  invert?: boolean;
}

export function ProviderIcon({ provider = "AWS", invert = false }: Props) {
  let ProviderLogo;

  switch (provider) {
    case "AWS":
      if (invert) {
        ProviderLogo = AwsLogoWhite;
      } else {
        ProviderLogo = AwsLogo;
      }
      break;
    case "Azure":
      ProviderLogo = AzureLogo;
      break;
    case "GCP":
      ProviderLogo = GcpLogo;
      break;
    case "TencentCloud":
      ProviderLogo = TencentCloudLogo;
      break;
    case "Kubernetes":
      ProviderLogo = KubernetesLogo;
      break;
    default:
      ProviderLogo = AwsLogo;
  }

  const id = `provider-tooltip-${nanoid()}`;

  return (
    <Box
      sx={{
        display: "flex",
        height: "25px",
        width: "25px",
      }}
    >
      <Tooltip data-testid="provider-tooltip" id={id} title={provider}>
        <span>
          <ProviderLogo aria-labelledby={id} height={25} width={25} />
        </span>
      </Tooltip>
    </Box>
  );
}
