import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import * as Sentry from "@sentry/browser";
import { Button, COLORS, theme, WithCopyButton } from "@stacklet/ui";
import { graphql, useFragment, useMutation } from "react-relay";

import { useAlertContext } from "app/contexts";
import { SetAwsAccessRolesMutation } from "app/mutations/SetAwsAccessRoles";
import CloudSecurity from "assets/icons/cloud_security.svg?react";

import { GatewayRolesForm, setAwsAccessRolesUpdater } from "./GatewayRolesForm";

import type { AccessModelTab_awsAccessRoles$key } from "./__generated__/AccessModelTab_awsAccessRoles.graphql";
import type { SetAwsAccessRolesMutation as SetAwsAccessRolesMutationType } from "app/mutations/__generated__/SetAwsAccessRolesMutation.graphql";

const pxToRem = theme.typography.pxToRem;

const StyledListItem = styled(ListItem)({
  fontSize: `${pxToRem(12)}!important`,
  color: COLORS.navy.L30,
  display: "list-item!important",
  listStyleType: "disc",
});

function getExecutionRole(samplePrimaryTrust: string) {
  let executionRole = "";
  try {
    const awsPrincipal =
      JSON.parse(samplePrimaryTrust).Statement[0]?.Principal.AWS;
    executionRole =
      awsPrincipal?.find((arn: string) => arn.includes("execution")) || "";
  } catch (e) {
    Sentry.captureException(e);
  }

  return executionRole;
}

interface Props {
  queryRef: AccessModelTab_awsAccessRoles$key;
}

export function AccessModelTab({ queryRef }: Props) {
  const { alertDispatch } = useAlertContext();

  const [updateAwsAccessRoles, isInFlight] =
    useMutation<SetAwsAccessRolesMutationType>(SetAwsAccessRolesMutation);

  const data = useFragment<AccessModelTab_awsAccessRoles$key>(
    graphql`
      fragment AccessModelTab_awsAccessRoles on Query
      @refetchable(queryName: "AccessModelTabRefetchQuery") {
        ...GatewayRolesForm_awsAccessRoles
        awsAccessRoles {
          externalID
          primaryRoleARN
          samplePrimaryTrust
        }
      }
    `,
    queryRef,
  );

  const roleKeys = [
    "primaryRoleARN",
    "readOnlyRoleARN",
    "accountDiscoveryRoleARN",
    "remediationPolicyRoleARN",
  ];
  const userHasGatewayRoles = roleKeys.some(
    (roleKey) =>
      !!data.awsAccessRoles[roleKey as keyof typeof data.awsAccessRoles],
  );
  const [wasGatewayRolesToggled, setWasGatewayRolesToggled] = useState(false);
  const [isGatewayRolesToggled, setIsGatewayRolesToggled] =
    useState(userHasGatewayRoles);

  useEffect(() => {
    setIsGatewayRolesToggled(userHasGatewayRoles);
  }, [userHasGatewayRoles]);

  function disableGatewayRoles() {
    updateAwsAccessRoles({
      variables: {
        input: {
          primary: null,
          readOnly: null,
          accountDiscovery: null,
          remediationPolicy: null,
        },
      },
      onCompleted(_, errors) {
        if (errors) {
          Sentry.captureException(errors);
        } else {
          alertDispatch({
            message: "Gateway roles disabled successfully",
            severity: "success",
          });
        }
      },
      onError: (error) => {
        Sentry.captureException(error);
      },
      updater: setAwsAccessRolesUpdater,
    });
  }

  const { primaryRoleARN, externalID, samplePrimaryTrust } =
    data.awsAccessRoles;

  const executionRoleARN = getExecutionRole(samplePrimaryTrust);
  return (
    <>
      <Dialog
        PaperProps={{
          sx: {
            maxWidth: pxToRem(700),
          },
        }}
        aria-label="disable-gateway-roles-modal"
        onClose={() => setWasGatewayRolesToggled(false)}
        open={wasGatewayRolesToggled}
      >
        <Box padding={`${pxToRem(24)} ${pxToRem(24)} 0`}>
          <Typography
            color={COLORS.neutrals.ash}
            fontSize={pxToRem(24)}
            fontWeight={700}
          >
            Disable Gateway Roles (Chain Assume Role)
          </Typography>
        </Box>
        <Box padding={pxToRem(24)}>
          <Typography fontSize={pxToRem(18)} marginBottom={pxToRem(16)}>
            Stacklet will use the default security context on each account
            rather than the gateway role.
          </Typography>
          <Typography fontSize={pxToRem(18)}>
            Ensure that the roles on each account have a valid trust
            relationship to the Stacklet execution role to ensure that AssetDB,
            Policy Execution, and Account Discovery continues uninterrupted.
          </Typography>
        </Box>
        <Box
          alignItems="center"
          borderTop={`1px solid ${COLORS.neutrals.denseFog}`}
          display="flex"
          justifyContent="space-between"
          padding={pxToRem(24)}
        >
          <Button
            buttonType="tertiary"
            onClick={() => setWasGatewayRolesToggled(false)}
          >
            Cancel
          </Button>
          <Button
            buttonType="main"
            loading={isInFlight}
            onClick={() => {
              setWasGatewayRolesToggled(false);
              disableGatewayRoles();
            }}
          >
            Disable Gateway Roles
          </Button>
        </Box>
      </Dialog>
      <Stack px={pxToRem(24)} py={pxToRem(5)} width={pxToRem(825)}>
        <Box alignItems="center" display="flex" gap={1} mb={pxToRem(32)}>
          <CloudSecurity />
          <Typography
            fontSize={pxToRem(18)}
            fontWeight={600}
            sx={{ textTransform: "uppercase" }}
          >
            Access Model
          </Typography>
        </Box>
        <Stack paddingLeft={pxToRem(4)} spacing={pxToRem(20)}>
          <Box alignItems="center" display="flex" gap={1}>
            <Switch
              checked={isGatewayRolesToggled}
              color="primary"
              onClick={() => {
                if (userHasGatewayRoles) {
                  setWasGatewayRolesToggled(true);
                } else {
                  setIsGatewayRolesToggled((prev) => !prev);
                }
              }}
            />
            Enable Gateway Roles (Chain Assume Role)
          </Box>
          <Stack
            direction="column"
            paddingLeft={pxToRem(16)}
            paddingRight={pxToRem(70)}
            spacing={pxToRem(20)}
          >
            <Typography
              sx={{
                fontSize: pxToRem(12),
                color: COLORS.navy.L30,
              }}
            >
              When <b>Gateway Roles (chain assume roles) are enabled</b>,
              Stacklet will first assume into the Gateway Roles you&apos;ve
              provided, and then assume into each accounts&apos; security
              context role. This allows you to disable Stacklet&apos;s policy
              execution access from a single role, by simply removing the trust
              relationship for Stacklet&apos;s execution role.
            </Typography>
            <List sx={{ paddingX: pxToRem(24), marginTop: "0!important" }}>
              <StyledListItem>
                To enable chain assume role, create a “Primary Role” that can
                trust the Stacklet execution role by using this Trust
                Relationship:{" "}
                <WithCopyButton
                  color={COLORS.orange.base}
                  copyLabel="Trust Relationship"
                  copyWithIcon={false}
                  sx={{ display: "inline-flex" }}
                  value={samplePrimaryTrust || ""}
                >
                  Copy Trust Relationship
                </WithCopyButton>
              </StyledListItem>
              <StyledListItem>
                Next, ensure that the Primary Role has the ability to assume
                into the roles available in each account&apos;s security
                context.
              </StyledListItem>
              <StyledListItem>
                Finally, add and save the Primary Role ARN.
              </StyledListItem>
            </List>
            <Stack marginTop="0!important" spacing={pxToRem(10)}>
              <Typography fontSize={pxToRem(14)}>
                Stacklet execution role
              </Typography>
              <Box
                border={`1px solid ${COLORS.navy.L70}`}
                borderRadius={pxToRem(4)}
                color={COLORS.orange.L20}
                fontWeight={600}
                paddingX={pxToRem(8)}
                width="fit-content"
              >
                {executionRoleARN}
              </Box>
              <Box
                alignItems="center"
                display="flex"
                gap={pxToRem(20)}
                position="relative"
              >
                <WithCopyButton
                  copyLabel="Role ARN"
                  copyWithIcon={false}
                  sx={{ fontSize: pxToRem(12) }}
                  value={primaryRoleARN || ""}
                >
                  Copy Role ARN
                </WithCopyButton>

                <Divider
                  sx={{
                    borderLeft: `1px solid ${COLORS.navy.D50}`,
                    height: "75%",
                    width: pxToRem(1),
                  }}
                />
                <WithCopyButton
                  copyLabel="External ID"
                  copyWithIcon={false}
                  sx={{ fontSize: pxToRem(12) }}
                  value={externalID || ""}
                >
                  Copy External ID
                </WithCopyButton>
              </Box>
            </Stack>
            {isGatewayRolesToggled ? (
              <GatewayRolesForm
                queryRef={data}
                viewOnly={userHasGatewayRoles}
              />
            ) : null}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
